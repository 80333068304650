import { getAddress } from 'viem'

// Quoter addresses for different chains
const QUOTER_ADDRESSES = {
  1: getAddress('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6'), // Mainnet
  123456: getAddress('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6'), // v-Mainnet
  137: getAddress('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6'), // Polygon
  12345: getAddress('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6'),
}

// Factory addresses for different chains
const FACTORY_ADDRESSES = {
  1: getAddress('0x1F98431c8aD98523631AE4a59f267346ea31F984'), // Mainnet
  123456: getAddress('0x1F98431c8aD98523631AE4a59f267346ea31F984'), // v-Mainnet
  137: getAddress('0x1F98431c8aD98523631AE4a59f267346ea31F984'),
  12345: getAddress('0x1F98431c8aD98523631AE4a59f267346ea31F984'),
}

// Uniswap Router Address
export const UNISWAP_ROUTER_CONTRACT_ADDRESS = getAddress(
  '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD'
)

// Function to get the Quoter address based on chain ID
export const getQuoterAddress = (chainId) => {
  if (QUOTER_ADDRESSES[chainId]) {
    return QUOTER_ADDRESSES[chainId]
  }
  throw new Error(`Unsupported chain ID: ${chainId}`)
}

// Function to get the Factory address based on chain ID
export const getFactoryAddress = (chainId) => {
  if (FACTORY_ADDRESSES[chainId]) {
    return FACTORY_ADDRESSES[chainId]
  }
  throw new Error(`Unsupported chain ID: ${chainId}`)
}

// TODO: replace by production data from database, etc.
export const TIME_INTERVAL = 108

export const MAX_SAFE_ALLOWANCE = '79228162514264337593543950335'
