import { AddressLookupTableAccount, PublicKey, TransactionInstruction, Connection } from "@solana/web3.js";
import { Buffer } from 'buffer'
import { connection } from '@/web3/config/onramp.js'


export const  createMemoInstruction = (memoText, payerPublicKey) => {
  return new TransactionInstruction({
    keys: [{ pubkey: payerPublicKey, isSigner: true, isWritable: true }],
    programId: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
    data: Buffer.from(memoText),
  });
}

// Function to retrieve Address Lookup Table Accounts based on an array of keys
export const getAdressLookupTableAccounts = async (keys) => {
  // Fetch multiple account infos using the connection
  const addressLookupTableAccountInfos = await connection.getMultipleAccountsInfo(
    keys.map((key) => new PublicKey(key))
  );

  // Reduce the fetched account infos into an array of AddressLookupTableAccounts
  return addressLookupTableAccountInfos.reduce((acc, accountInfo, index) => {
    const addressLookupTableAddress = keys[index];
    if (accountInfo) {
      const addressLookupTableAccount = new AddressLookupTableAccount({
        key: new PublicKey(addressLookupTableAddress),
        state: AddressLookupTableAccount.deserialize(accountInfo.data),
      });
      acc.push(addressLookupTableAccount);
    }

    return acc;
  }, []);
};

// Converts instruction data into a TransactionInstruction object
export const instructionDataToTransactionInstruction = (instructionPayload) => {
  if (!instructionPayload) {
    return null;
  }
  return new TransactionInstruction({
    programId: new PublicKey(instructionPayload.programId),
    keys: instructionPayload.accounts.map((key) => ({
      pubkey: new PublicKey(key.pubkey),
      isSigner: key.isSigner,
      isWritable: key.isWritable,
    })),
    data: Buffer.from(instructionPayload.data, "base64"),
  });
};

