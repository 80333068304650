import { Controller } from '@hotwired/stimulus'
import { get, post } from '@rails/request.js'
import { signDelegate } from '@/web3/logic/signDelegate.js'

export default class extends Controller {
  static targets = ['safesList', 'message']
  static outlets = ['app--wallet']

  connect() {
    if (this.appWalletOutlets[0].isConnected()) {
      fetchSafes()
    }
  }

  async fetchSafes() {
    if (this.appWalletOutlets[0].isConnected()) {
      this.safesListTarget.innerHTML = 'Wait, loading in progress...'
      const address = this.appWalletOutlets[0].getAddress()
      const chain_id = this.appWalletOutlets[0].getChainId()
      const response = await get(`/safes/fetchings?address=${address}&chain_id=${chain_id}`, {
        responseKind: 'turbo-stream',
      })
      this.safesListTarget.innerHTML = await response.text
    } else {
      this.safesListTarget.innerHTML = ''
    }
  }

  async addSafe(event) {
    const safeAddress = event.target.dataset.address
    const delegate = event.target.dataset.delegate
    const chainId = this.appWalletOutlets[0].getChainId()
    const delegator = this.appWalletOutlets[0].getAddress()
    const signature = await signDelegate(delegate, chainId)

    await post('/safes', {
      body: JSON.stringify({
        chain: chainId,
        signature: signature,
        safe_address: safeAddress,
        delegator: delegator,
      }),
      headers: { 'Content-Type': 'application/json' },
      responseKind: 'turbo-stream',
    })
  }

  refresh() {
    this.fetchSafes()
  }
}
