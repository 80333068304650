import { post, put, get } from '@rails/request.js'
import { modal } from '@/web3/config/onramp.js'

export class Charge {
  static current = {}

  constructor(account, chain) {
    Charge.current.address = account.address
    Charge.current.chain = chain
  }

  static async getData( email = null) {
    Charge.current.address = address
    Charge.current.chain = chainID

    const response = await post(window.location.href + '/charge', {
      body: JSON.stringify({
        charge: { payee: address, chain_id: chainId },
      }),
      headers: { 'Content-Type': 'application/json' },
    })

    if (response.ok) {
      Charge.current.data = await response.json
    }
    return Charge.current.data
  }

  static async create(email) {
    const address = modal.getAddress();
    const chainId = modal.getCaipNetwork().id; 

    const response = await post(window.location.href + '/charges', {
      body: JSON.stringify({
        charge: {
          payee: address,
          chain_id: chainId, 
          email: email
        }
      }),
      headers: { 'Content-Type': 'application/json' },
    });

    return await response.json;
  }


static async processing(chargeId, hash) {

  if (!chargeId) {
    console.error("Charge ID is missing or invalid.");
    return;
  }

  const chainId = modal.getCaipNetwork().id;

  const body = {
    charge: { 
      tx: hash.toString()
    },
  };
    const response = await put(window.location.href +`/processing_charges/${chargeId}`, {
      body: JSON.stringify(body),
      responseKind: 'turbo-stream',
    });

   return response.status === 204
}



  static async payGasLess(signature, chargeId, email = null) {
    const body = { charge: { owner: signature.owner, signature: signature.signature } }
    if (email) body.charge.email = email

    try {
      const response = await post(window.location.href + `/gas_less_charge/${chargeId}`, {
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' },
        responseKind: 'turbo-stream',
      })

      const tx = Charge.extractTransactionHash(await response.text)

      if (!response.ok) {
        console.error('Failed to update charge status:', response)
      }
      return tx
    } catch (error) {
      console.error('Error updating charge status:', error)
    }
  }

  static extractTransactionHash(htmlString) {
    const regex = /data-hash="(0x[0-9a-fA-F]+)"/
    const match = htmlString.match(regex)
    return match ? match[1] : null
  }
}
