import { Controller } from '@hotwired/stimulus'
import { getGasPrice } from '@wagmi/core'
import { getNetworkFee } from '@/web3/utils/networkFee'

import { getAddress, parseEther, formatEther, formatGwei } from 'viem'
import { getTokenPriceRep } from '../../web3/services/tokenPriceService'

export default class extends Controller {
  static targets = ['usd', 'native', 'total', 'details']
  static outlets = ['app--wallet']

  async showNetworkFee() {
    const chain = this.appWalletOutlet.chain
    const total = Number(this.element.dataset.total)
    const priceRep = await getTokenPriceRep(chain.wrapped_native, chain)
    const { feeNative, feeRep } = await getNetworkFee(chain, chain.gas)

    this.nativeTarget.innerHTML = `~${feeNative.toFixed(5)} ${chain.currency}`
    this.usdTarget.innerHTML = ` ~$${feeRep.toFixed(3)} USD`
    this.totalTarget.innerHTML = ` ~$${(total + feeNative * priceRep).toFixed(2)} USD`
  }

  hide(){
    this.detailsTarget.classList.add('hidden')
  }

  show(){
    this.detailsTarget.classList.remove('hidden')
    this.showNetworkFee()
  }
}
