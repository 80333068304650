import { Controller } from '@hotwired/stimulus'
import { processTransaction } from '@/web3/services/transactionService.js'
import { waitForTransactionReceipt } from '@wagmi/core'
import { getNetworkFee } from '@/web3/utils/networkFee'
import { RenderCheckout } from '@/web3/ui/checkoutUi'
import dateNow from '@/web3/utils/dateNow'
import { Charge } from '@/web3/logic/charge.js'

export default class extends Controller {
 static targets = [
   'token',
   'txHash', 
   'receipt',
   'info',
   'paymentResult',
   'paymentDetails',
   'from',
   'network',
   'subtotal',
   'total',
   'fee',
   'date',
   'status',
   'paymentBox',
   'connect',
   'pay',
   'email',
   'payButton',
   'spinner',
   'payButtonTittle',
   'walletInfoButton'
 ]

 static values = {
   token: Object,
   amount: Number,
   chains: Array
 }

 static outlets = ['app--wallet', 'web3--tokens']

 connect() {
   document.addEventListener('transactionServiceEvent', this.handleTransactionServiceEvent)
   
   this.appWalletOutlet.modal.subscribeEvents((event) => {
     switch (event.data.event) {
       case 'MODAL_CLOSE':
       case 'MODAL_OPEN': 
         this.syncronise()
         break
     }
   })
 }

 syncronise() {
   if (this.appWalletOutlet.isConnected) {
     this.payTarget.classList.remove('hidden')
     this.connectTarget.classList.add('hidden') 
     this.walletInfoButtonTarget.classList.remove('hidden')
   } else {
     this.payTarget.classList.add('hidden')
     this.connectTarget.classList.remove('hidden')
     this.walletInfoButtonTarget.classList.add('hidden')
   }
 }

 handleTransactionServiceEvent = async (event) => {
   const { chargeId, state, hash, error, chain, receipt, charge } = event.detail

   switch (state) {
     case 'processing':
       this.paymentBoxTarget.classList.add('hidden')
       document.getElementById('charge').innerHTML = `
         <turbo-frame id="charge_${chargeId}">
           <div data-visibility-target="loader" class="absolute inset-0 rounded-[20px] overflow-hidden">
             <div class="loaderOverlay loader absolute inset-0 bg-[#141318] flex items-center justify-center z-50">
               <svg viewBox="25 25 50 50">
                 <circle class="circle-large" cx="50" cy="50" r="20"></circle>
                 <g style="transform-origin: 50px 50px; transform: scaleX(-1);">
                   <circle class="circle-small" cx="50" cy="50" r="15"></circle>
                 </g>
               </svg>
             </div>
           </div>
         </turbo-frame>
       `
       await Charge.processing(chargeId, hash)
       break

     case 'failed':
       await this.renderFail(error)
       break

     case 'approve':
       this.payButtonTittleTarget.innerHTML = `Unlocking ${event.detail.token.symbol}`
       break

     case 'approved': 
       this.payButtonTittleTarget.innerHTML = `Proceeding with payment`
       break

     default:
       console.warn(`Unknown transaction state: ${state}`)
   }
 }

 selectToken = async (event) => {
   if (!this.emailTarget.value.trim()) {
     return
   }

   try {
     this.statusTarget.innerHTML = ""
     const tokenSelected = this.web3TokensOutlet.selectedToken
     const chain = this.appWalletOutlet.chain
     const email = this.emailTarget.value.trim()

     this.spinnerTarget.classList.toggle('hidden', false)
     this.toggleButtonState(false)

     await processTransaction(tokenSelected, chain, this.amountValue, this.emailTarget.value)
   } catch(error) {
     console.error(error)
     this.renderFail(error.details)
     this.toggleButtonState(true)
   }
 }

 checkEmail = () => {
   this.toggleButtonState(this.emailTarget.value.trim() !== '')
 }

 toggleInfo = () => {
   this.infoTarget.classList.toggle('hidden')
   this.paymentResultTarget.classList.toggle('hidden')
 }

 toggleButtonState = (enable) => {
   this.payButtonTarget.disabled = !enable
   this.payButtonTarget.classList.toggle('opacity-50', !enable)
 }

 async renderFail(errorMessage) {
   this.toggleButtonState(true)
   this.spinnerTarget.classList.toggle('hidden', true) 
   this.payButtonTittleTarget.innerHTML = `Pay`
   this.statusTarget.innerHTML = RenderCheckout.paymentInfo(
     errorMessage, 
     RenderCheckout.status('failed')
   )
 }
}