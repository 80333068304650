import { prepareTransactionRequest, writeContract } from '@wagmi/core'
import { IERC20_ABI } from '@/web3/abis/IERC20_ABI'
import { config } from '@/web3/config/onramp.js'

export const approve = async (tokenAddress, spenderAddress, amount) => {
  const request = await prepareTransactionRequest(config, {
    address: tokenAddress,
    abi: IERC20_ABI,
    functionName: 'approve',
    args: [spenderAddress, amount],
    gas: 1500000,
  })
  const hash = await writeContract(config, request)
  return hash
}
