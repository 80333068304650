import { getGasPrice } from '@wagmi/core'
import { formatEther, formatGwei } from 'viem'
import { getTokenPriceRep } from '../../web3/services/tokenPriceService'
import { modal, config } from "@/web3/config/onramp.js";


export async function getNetworkFee(chain, gasUsed) {
  const priceRep =  await getTokenPriceRep(chain.wrapped_native, chain)
  const gasPrice = await getGasPrice(config)
  const fee = gasPrice * BigInt(gasUsed)
  const feeNative = Number(formatEther(fee))
  const feeRep = Number(feeNative * priceRep)
  return { feeNative, feeRep }
}
