import { signTypedData, getAccount } from '@wagmi/core'

export async function signDelegate(delegateAddress, chainId, provider) {
  const { account } = getAccount(provider)

  const domain = {
    name: 'Safe Transaction Service',
    version: '1.0',
    chainId: chainId,
  }

  const types = {
    Delegate: [
      { name: 'delegateAddress', type: 'address' },
      { name: 'totp', type: 'uint256' },
    ],
  }

  const totp = Math.floor(Date.now() / 1000 / 3600)

  const result = await signTypedData(provider, {
    account,
    domain,
    types,
    primaryType: 'Delegate',
    message: { delegateAddress, totp },
  })

  return result
}
