import {
  createAssociatedTokenAccountInstruction,
  getAssociatedTokenAddress,
  createTransferCheckedInstruction,
  TOKEN_PROGRAM_ID,
  getMint,
} from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import { connection, modal } from '@/web3/config/onramp.js';

// Ensure the destination account is an associated token account for the specified token
async function ensureAssociatedTokenAccount(payer, owner, mint) {
  const associatedTokenAddress = await getAssociatedTokenAddress(mint, owner);
  const accountInfo = await connection.getAccountInfo(associatedTokenAddress);

  if (!accountInfo) {
    const instruction = createAssociatedTokenAccountInstruction(
      payer,
      associatedTokenAddress,
      owner,
      mint,
    );
    return { instruction, associatedTokenAddress };
  }

  return { instruction: null, associatedTokenAddress };
}

export async function transfer(
  toPublicKey,
  amountInLamports,
  mint
) {
  const {address, decimals} = mint
  const provider = modal.getWalletProvider();
  const mintPublicKey = new PublicKey(address);

  const fromTokenAccount = await getAssociatedTokenAddress(
    mintPublicKey,
    provider.publicKey,
  );

  const {
    instruction: createToAccountIx,
    associatedTokenAddress: toTokenAccount,
  } = await ensureAssociatedTokenAccount(
    provider.publicKey,
    toPublicKey,
    mintPublicKey,
  );

  const instructions = [];
  
  if (createToAccountIx) instructions.push(createToAccountIx);

  const transferCheckedIx = createTransferCheckedInstruction(
    fromTokenAccount, // Source token account
    mintPublicKey, // The token mint
    toTokenAccount, // Destination token account
    provider.publicKey, // Owner of the source account
    amountInLamports, // Amount to transfer, in lamports
    decimals, // Number of decimals for the token
    [], // Signers (empty because the owner is signing)
    TOKEN_PROGRAM_ID, // Token program ID
  );

  instructions.push(transferCheckedIx);
  return instructions;
}
