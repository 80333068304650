// utils/eventDispatcher.js

/**
 * Dispatches a custom event with the provided state and detail.
 * @param {string} state - The state of the transaction (e.g., 'processing', 'success', 'failed').
 * @param {object} detail - Additional details related to the transaction event.
 */
export function dispatchTransactionEvent(state, detail) {
  const event = new CustomEvent('transactionServiceEvent', {
    detail: { state, ...detail },
  });
  document.dispatchEvent(event);
}
