import { SolanaAdapter } from '@reown/appkit-adapter-solana';
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi';
import { solana, polygon } from '@reown/appkit/networks';
import { SolflareWalletAdapter, PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { createAppKit } from '@reown/appkit';
import { getConnectorClient } from '@wagmi/core';
import { Connection } from "@solana/web3.js";

export const projectId = '96179c13183c2bc6a73ae15c3e8b2541';
export const connection = new Connection("https://mainnet.helius-rpc.com/?api-key=80496f94-37b5-487c-a9e2-ecde5aead43c");

export const metadata = {
  name: 'scionx',
  description: 'scionx',
  url: 'https://scionx.dev',
  icons: ['https://avatars.githubusercontent.com/u/179229932'],
};

export const polygonFork = {
  ...polygon,
  name: 'Polygon Virtual',
  id: 12345,
  rpcUrls: {
    default: {
      http: ['https://virtual.polygon.rpc.tenderly.co/bf563b1f-c1ad-41dc-a4aa-91da6df3bf2e'],
    },
  },
}

export let modal;
export let config;

export function setupAppKit(chainsEnabled) {
  let networks = [];
  let evm = [];

  const chainsArray = [polygon, polygonFork];

  chainsEnabled.forEach((chain) => {
      const result = chainsArray.find(x => x.id === Number(chain.chain_id));
      if (typeof result != "undefined") {
          evm.push(result);
      }
      if (chain.name_space === "solana") {
          networks.push(solana);
      }
  });

  const wagmiAdapter = new WagmiAdapter({
    projectId,
    networks: evm,
  });

  const solanaWeb3JsAdapter = new SolanaAdapter({
    wallets: [new PhantomWalletAdapter(), new SolflareWalletAdapter()],
  });

  modal =  createAppKit({
    adapters: [wagmiAdapter, solanaWeb3JsAdapter],
    networks: [...evm, ...networks],
    metadata: metadata,
    projectId: projectId,
    allowUnsupportedChain: true,
    allWallets: 'ONLY_MOBILE',
    features: {
      analytics: false,
      email: false,
      socials: [],
      swaps: false,
      onramp: false,
    },
  });

  config = wagmiAdapter.wagmiConfig
  return modal
}

export async function getClient() {
  return await getConnectorClient(config);
}

export async function getChainId() {
  const client = await getClient();
  return client.chain.id;
}
