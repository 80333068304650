import { quoteExactOutput } from '@/web3/logic/uniswapQuote.js';
import { getTokenAllowance } from '@/web3/logic/getTokenAllowance.js';
import { approve } from '@/web3/logic/approve.js';
import { Checkout } from '@/web3/logic/checkout.js';
import { Charge } from '@/web3/logic/charge.js';
import { getSignature } from '@/web3/utils/permit.js';
import { fromReadableAmount, bigIntify } from '@/web3/utils/conversion.js';
import { ONE_PERCENT, percent_mul } from '@/web3/utils/percentage.js';
import { isAddressEqual, getAddress } from 'viem';
import { FeeAmount } from '@uniswap/v3-sdk';
import { Token } from '@uniswap/sdk-core';
import { post, put } from '@rails/request.js';
import { swapAndTransfer, transferToDestinations } from '@/web3/logic/solanaSwapAndTransfer.js';
import { TransferredEventDecoder } from '@/web3/utils/transferredEventDecoder.js'
import { modal } from '@/web3/config/onramp.js'
import { dispatchTransactionEvent } from "@/web3/utils/eventDispatcher.js";

export async function processTransaction(tokenSelected, chain, amountValue, email) {
  let hash;
  let chargeId;
  let receipt
  const charge = await Charge.create(email); 

  if (chain.name_space === 'solana') {
    // Retrieve the charge and transfer intent
    if (chain.recipient_currency.address == tokenSelected.address) {
      hash = await transferToDestinations(tokenSelected.address, charge, chain);
    } else {
      hash = await swapAndTransfer(tokenSelected.address, charge, chain);
    }
  } else {
    // EVM-based chain logic initialization
    let inputAmount;
    let maxWillingToPay;
    let checkout;
    let signature;

    // If tokenSelected is different from the recipient currency, quote an exact output
    if (tokenSelected.symbol != chain.recipient_currency.symbol) {
      inputAmount = (
        await quoteExactOutput(
          fromReadableAmount(amountValue,  chain.recipient_currency.decimals),
           new Token(
              Number(chain.chain_id),
              getAddress(tokenSelected.address),
              tokenSelected.decimals,
              tokenSelected.symbol,
              tokenSelected.name
            ),
          new Token(
            Number(chain.chain_id),
            getAddress(chain.recipient_currency.address),
            chain.recipient_currency.decimals,
            chain.recipient_currency.symbol,
            chain.recipient_currency.name
          ),
          FeeAmount.MEDIUM
        )
      ).result;

      // Calculate maxWillingToPay with slippage
      maxWillingToPay = percent_mul(inputAmount, ONE_PERCENT) + bigIntify(inputAmount);
    }

    // Retrieve charge data and initialize checkout
    chargeId = charge.web3_data.transfer_intent.metadata.public_id;
    checkout = new Checkout(charge.web3_data.transfer_intent);

    // Process based on the token type and chain currency
    if (tokenSelected.symbol === chain.currency) {
      hash = await checkout.swapAndTransferNative(maxWillingToPay);
    } else if (isAddressEqual(tokenSelected.address, chain.recipient_currency.address)) {
      dispatchTransactionEvent('approve', { token: tokenSelected, maxWillingToPay });
      signature = await getSignature(chain, tokenSelected, charge.web3_data.transfer_intent.call_data);
      dispatchTransactionEvent('approved', { token: tokenSelected, maxWillingToPay });
      hash = await Charge.payGasLess(signature, chargeId);
    } else {
      // Check and approve token allowance if necessary
      const allowance = await getTokenAllowance(
        tokenSelected.address,
        chain.contract_address,
        modal.getAddress()
      );
      if (allowance < maxWillingToPay) {
        dispatchTransactionEvent('approve', { token: tokenSelected, maxWillingToPay });
        await approve(tokenSelected.address, chain.contract_address, maxWillingToPay);
        dispatchTransactionEvent('approved', { token: tokenSelected, maxWillingToPay });
      }
      hash = await checkout.swapAndTransferToken(tokenSelected.address, maxWillingToPay);
    }
      dispatchTransactionEvent('processing', { chargeId: chargeId, hash: hash});
  }
}
