import { walletConnect, coinbaseWallet } from '@wagmi/connectors'
import { createConfig, http } from '@wagmi/core'
import { mainnet, polygon, sepolia } from '@wagmi/core/chains'

export const polygonFork = {
  ...polygon,
  name: 'Polygon Virtual',
  id: 12345,
  rpcUrls: {
    default: {
      http: ['https://virtual.polygon.rpc.tenderly.co/5a8b7959-0296-4f26-a662-ea4f5369e7ee'],
    },
  },
}

export const mainnetFork = {
  ...mainnet,
  name: 'Mainnet Virtual',
  id: 123456,
  rpcUrls: {
    default: {
      http: ['https://virtual.mainnet.rpc.tenderly.co/2aa22531-73fc-4e61-8c62-d14c4f2b020e'],
    },
  },
}

export const projectId = '96179c13183c2bc6a73ae15c3e8b2541'
export const connectors = [
  coinbaseWallet({ appName: 'scionx', preference: 'smartWalletOnly' }),
  walletConnect({
    projectId: projectId,
  }),
]

export class Wagmi {
  static config = createConfig({
    chains: [polygonFork, polygon, sepolia, mainnetFork],
    connectors: connectors,
    transports: {
      [polygon.id]: http('https://polygon.gateway.tenderly.co/5IDdy2wbxbbvuQmhXO8Cgj'),
      [polygonFork.id]: http(
        'https://virtual.polygon.rpc.tenderly.co/5a8b7959-0296-4f26-a662-ea4f5369e7ee'
      ),
      [sepolia.id]: http('https://sepolia.gateway.tenderly.co/693RU3w6Dh2rzBUzQjFGQ5'),
      [mainnetFork.id]: http(
        'https://virtual.mainnet.rpc.tenderly.co/fe6340ce-2a48-40bf-829c-b0229e98ad71'
      ),
    },
  })
}

export function config() {
  return Wagmi.config
}
