import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import { Token } from '@uniswap/sdk-core'
import { config } from '@/web3/config/onramp.js'
import { getAccount } from '@wagmi/core'

export default class extends Controller {
 static outlets = ['visibility', 'app--wallet', 'web3--token', 'web3--network-fee']
 
 static values = {
   showTokens: false,
 }

 async connect() {
   const outlet = this.appWalletOutlets[0]
   
   outlet.modal.subscribeEvents(event => {
     console.log(event.data.event)
     if (event.data.event == "CONNECT_SUCCESS") {
       //if (this.listenersAttached) {
         this.syncAccount()
       //}
     }
     if (event.data.event == "SELECT_WALLET") {
      // this.syncAccount()
     }
   })

   outlet.modal.subscribeState(async (state) => {
     if (!state.loading && !state.open && outlet.modal.getIsConnectedState()) {
       initializeProvider()
     }
   })

   const initializeProvider = () => {
     const provider = outlet.provider
     if (provider && !this.listenersAttached) {
      // this.syncAccount()
       provider.on('accountsChanged', () => this.syncAccount())
       provider.on('chainChanged', () => this.syncAccount())
       this.listenersAttached = true
     }
   }
 }

 async syncAccount() {
   this.visibilityOutlet.showLoader()
   const payerAddress = this.appWalletOutlet.address
   const chainId = this.appWalletOutlet.chainId
   this.selectedTokenIndex = 0

   try {
     await get(`/wallet?address=${payerAddress}&chain_id=${chainId}`, {
       contentType: 'application/json',
       responseKind: 'turbo-stream',
     })
     
     if (this.appWalletOutlet.chain.name_space != "solana") {
       this.web3NetworkFeeOutlet.show()
     } else {
       this.web3NetworkFeeOutlet.hide()
     }
   } catch (error) {
     console.error('Error fetching wallet data:', error)
   } finally {
     this.visibilityOutlet.hideLoader()
   }
 }

 handleClick(e) {
   if (this.showTokensValue) {
     this.setSelectToken(e.currentTarget)
   } else {
     this.showTokens()
   }
 }

 showTokens() {
   this.showTokensValue = true
   this.web3TokenOutlets.forEach((web3Token) => {
     web3Token.toggle(!this.showTokensValue)
   })
   this.visibilityOutlet.showTokens()
 }

 setSelectToken(tokenElement) {
   this.visibilityOutlet.showLoader()
   this.showTokensValue = false
   this.web3TokenOutlets.forEach((web3Token) => {
     web3Token.toggle(web3Token.tokenTarget !== tokenElement)
     if (web3Token.tokenTarget === tokenElement) {
       this.selectedTokenIndex = web3Token.indexValue
     }
   })
   this.visibilityOutlet.hideTokens()
 }

 get selectedToken() {
   return this.web3TokenOutlets[this.selectedTokenIndex].tokenValue
 }

 dispatchEvent(eventName, detail) {
   document.dispatchEvent(new CustomEvent(eventName, { detail }))
 }
}