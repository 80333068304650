import { Controller } from '@hotwired/stimulus'
import { getTokenPriceRep } from '@/web3/services/tokenPriceService.js'
import { Token } from '@uniswap/sdk-core'
import { getAddress } from 'viem'
import { Charge } from '@/web3/logic/charge.js'

export default class extends Controller {
  static targets = ['price', 'token']
  static outlets = ['app--wallet']
  static values = {
    token: Object,
    index: Number,
  }

  async connect() {
    this.initializeTokenVisibility()
    this.scheduleTokenBalanceUpdates()
  }

  disconnect() {
    clearInterval(this.updateInterval)
  }

  initializeTokenVisibility() {
    const symbol = this.appWalletOutlet.chain.recipient_currency.symbol
    if (this.tokenValue.symbol == symbol) {
      this.element.classList.remove('hidden');
      const chain = this.appWalletOutlet.chain;
      if (chain.name_space === 'solana') {
        this.dispatchEvent('tokenSelected', this.tokenValue.address);
      } else {
        this.dispatchEvent(
          'tokenSelected',
          new Token(
            this.tokenValue.chain_id,
            this.tokenValue.address,
            this.tokenValue.decimals,
            this.tokenValue.symbol,
            this.tokenValue.name
          )
        );
      }
    }
  }

  // updating token balance
  scheduleTokenBalanceUpdates() {
    this.updateTokenBalanceDisplay()
    this.updateInterval = setInterval(() => {
      this.updateTokenBalanceDisplay()
    }, 50000) // each 10s
  }

  async updateTokenBalanceDisplay() {
    const token = this.tokenValue
    const chain = this.appWalletOutlet.chain
    try {
      const usdcPrice = await getTokenPriceRep(token, chain)
      const balanceInUsdc = (token.token_balance * usdcPrice).toFixed(2)
      if (balanceInUsdc > 0) {
        this.priceTarget.innerHTML = `$${balanceInUsdc}`
        this.priceTarget.classList.remove('hide-element')
      } else {
        this.priceTarget.classList.add('hide-element')
      }
    } catch (error) {
      console.log(`Error ${error}:`)
    }
  }

  toggle(state) {
    this.tokenTarget.classList.toggle('hidden', state)
  }

  // help functions

  dispatchEvent(eventName, detail) {
    document.dispatchEvent(new CustomEvent(eventName, { detail }))
  }
}
