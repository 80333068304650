import {
  Connection,
  PublicKey,
  VersionedTransaction,
  TransactionMessage,
  TransactionInstruction,
  SystemProgram,
  Keypair,
  Transaction,
  LAMPORTS_PER_SOL,
  ComputeBudgetInstruction
} from "@solana/web3.js";
import { ACCOUNT_SIZE } from "@solana/spl-token";
import { getSwapIx, getQuote as fetchQuote } from "@/web3/services/jupiterApi.js";
import { transfer } from "@/web3/utils/tokenTransfers.js";
import {
  getAdressLookupTableAccounts,
  instructionDataToTransactionInstruction,
  createMemoInstruction
} from "@/web3/utils/solanaUtils.js";
import { dispatchTransactionEvent } from "@/web3/utils/eventDispatcher.js";
import { modal, connection } from "@/web3/config/onramp.js";
import { getAssociatedTokenAddress } from "@solana/spl-token";

// Function to create a Jupiter Swap transaction
async function createJupiterSwapTransaction(checkoutAmount, payeeCurrency, merchantCurrency) {
  const provider = window.phantom?.solana;
  const wallet = provider.wallet;

  const quoteResponse = await fetchQuote(payeeCurrency, merchantCurrency, checkoutAmount);
  if (quoteResponse.error) {
    console.error("Quote Error:", quoteResponse.error);
    return null;
  }

  const swapResponse = await getSwapIx(provider.publicKey, quoteResponse);
  if ("error" in swapResponse) {
    console.error("Swap Error:", swapResponse.error);
    return null;
  }

  return swapResponse;
}

export async function transferToDestinations(payeeCurrency, charge, chain) {
  const chargeId = charge.web3_data.metadata.public_id;
  const transferIntent = charge.web3_data.transfer_intent;

  const provider = modal.getWalletProvider();
  const recipientCurrency = chain.recipient_currency;
  const { recipient_amount, fee_amount, recipient, operator } = transferIntent;

  const recipientPubKey = new PublicKey(recipient);
  const operatorPubKey = new PublicKey(operator);
  const mintPublicKey = new PublicKey(recipientCurrency.address);

  // Prepare instructions for recipient transfer
  const transferToRecipientInstructions = await transfer(
    recipientPubKey,
    recipient_amount,
    recipientCurrency
  );

  // Prepare instructions for operator transfer
  const transferToOperatorInstructions = await transfer(
    operatorPubKey,
    fee_amount,
    recipientCurrency
  );

  // Combine instructions into a single transaction
  const transaction = new Transaction().add(
    ...transferToRecipientInstructions,
    ...transferToOperatorInstructions
  );

  transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
  transaction.feePayer = provider.publicKey;

  try {
    const signedTransaction = await provider.signTransaction(transaction);
    const signature = await connection.sendRawTransaction(signedTransaction.serialize());

    dispatchTransactionEvent('processing', { chargeId, chain: 'solana', hash: signature });
    return signature;
  } catch (e) {
    dispatchTransactionEvent('failed', { chargeId, error: e.message });
    console.error("Transaction failed:", e);
  }
}


// Swap and transfer function that takes in a TransferIntent
export async function swapAndTransfer(payeeCurrency, charge, chain) {

  const chargeId  = charge.web3_data.metadata.public_id;
  const transferIntent = charge.web3_data.transfer_intent;

  const provider = modal.getWalletProvider();
  const recipient_currency  = chain.recipient_currency;

  try {
    const {
      recipient_amount,
      deadline,
      recipient,
      fee_amount,
      operator,
    } = transferIntent;

    const checkoutAmount = recipient_amount + fee_amount;
    const transaction = await createJupiterSwapTransaction(checkoutAmount, payeeCurrency, recipient_currency.address);

    if (!transaction) {
      console.error("Transaction creation failed.");
      return;
    }

    const {
      computeBudgetInstructions,
      setupInstructions,
      swapInstruction,
      cleanupInstruction,
      addressLookupTableAddresses,
    } = transaction;

    const addressLookupTableAccounts = await getAdressLookupTableAccounts(addressLookupTableAddresses);

    const transferToRecipientInstructions = await transfer(
      new PublicKey(recipient),
      recipient_amount,
      recipient_currency
    );

    const transferToOperatorInstructions = await transfer(
      new PublicKey(operator),
      fee_amount,
      recipient_currency
    );

    const memoContent = `${operator}:${chargeId}`;
    const memoInstruction = createMemoInstruction(memoContent, provider.publicKey);

    // Create rent flash loan instructions
    const ataRent = await connection.getMinimumBalanceForRentExemption(ACCOUNT_SIZE);

    // Deduct the rent directly from the user's wallet to create the ATA
    const borrowIx = SystemProgram.transfer({
      fromPubkey: provider.publicKey,
      toPubkey: provider.publicKey, // Charging the user themselves to cover rent costs
      lamports: ataRent,
    });

    // Budget instructions processing
    const budgetInstructions = computeBudgetInstructions.map(instructionDataToTransactionInstruction);

    // Calculate fee based on compute units
    let fee = 10000;
    if (budgetInstructions.length > 0) {
      let units = 0;
      let price = 0;
      budgetInstructions.forEach((instr) => {
        const type = ComputeBudgetInstruction.decodeInstructionType(instr);
        switch (type) {
          case "SetComputeUnitLimit":
            units = ComputeBudgetInstruction.decodeSetComputeUnitLimit(instr).units;
            break;
          case "SetComputeUnitPrice":
            price = Number(ComputeBudgetInstruction.decodeSetComputeUnitPrice(instr).microLamports);
            break;
        }
      });
      fee += Math.ceil((units * price) / 1000000);
      if (fee / LAMPORTS_PER_SOL > 0.01) {
        throw new Error("Priority fees are too high right now, try again later");
      }
    }

    // Repay instruction for rent loan
    const repayIx = SystemProgram.transfer({
      fromPubkey: provider.publicKey,
      toPubkey: provider.publicKey,
      lamports: ataRent + fee,
    });

    // Construct the complete instructions array
    const instructions = [
      ...budgetInstructions,
      borrowIx,
      ...setupInstructions.map(instructionDataToTransactionInstruction),
      instructionDataToTransactionInstruction(swapInstruction),
      ...transferToRecipientInstructions,
      ...transferToOperatorInstructions,
      repayIx,
      cleanupInstruction ? instructionDataToTransactionInstruction(cleanupInstruction) : null,
    ].filter(Boolean);

    const { blockhash, lastValidBlockHeight } = await connection.getLatestBlockhash('finalized');

    // Compile the transaction message using V0
    const messageV0 = new TransactionMessage({
      payerKey: provider.publicKey,
      recentBlockhash: blockhash,
      instructions,
    }).compileToV0Message(addressLookupTableAccounts);

    // Create a veharge_set_status_job.rb:21rsioned transaction
    const versionedTransaction = new VersionedTransaction(messageV0);

    try {
      const signature = await provider.signAndSendTransaction(versionedTransaction);
      dispatchTransactionEvent('processing', { chargeId: chargeId,  chain: 'solana', hash: signature});
      return signature;
    } catch (e) {
      dispatchTransactionEvent('failed', { chargeId: chargeId, error: e.message });
      console.error("Transaction failed:", e);
    }
  } catch (error) {
    dispatchTransactionEvent('failed', { charge: charge,  error: error.message });
    console.error("Error during swap and transfer:", error);
  }
}
