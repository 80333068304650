import { readContract, simulateContract } from '@wagmi/core'
import IUniswapV3PoolABI from '@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json'
import Quoter from '@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json'
import { computePoolAddress } from '@uniswap/v3-sdk'
import { getQuoterAddress, getFactoryAddress } from '@/web3/config/constants.js'
import { modal, config } from "@/web3/config/onramp.js";


export const currentPoolAddress = async (tokenIn, tokenOut, feeAmount) => {
  return computePoolAddress({
    factoryAddress: getFactoryAddress(modal.getChainId()),
    tokenA: tokenIn,
    tokenB: tokenOut,
    fee: feeAmount,
  })
}

export const quoteExactInput = async (amountIn, tokenIn, tokenOut, feeAmount, provider) => {
  const poolFees = await getPoolFee(tokenIn, tokenOut, feeAmount)
  const quotedAmountOut = await simulateContract(client, {
    abi: Quoter.abi,
    address: getQuoterAddress(client.chain.id),
    functionName: 'quoteExactInputSingle',
    args: [tokenIn.address, tokenOut.address, poolFees, amountIn, 0],
  })

  return quotedAmountOut
}

export const quoteExactOutput = async (amountOut, tokenIn, tokenOut, feeAmount) => {
  const poolFees = await getPoolFee(tokenIn, tokenOut, feeAmount)

  const quotedAmountIn = await simulateContract(config, {
    abi: Quoter.abi,
    address: getQuoterAddress(tokenIn.chainId),
    functionName: 'quoteExactOutputSingle',
    args: [tokenIn.address, tokenOut.address, poolFees, amountOut, 0],
  })

  return quotedAmountIn
}

export const getPoolFee = async (tokenIn, tokenOut, feeAmount) => {
  const poolAddress = await currentPoolAddress(tokenIn, tokenOut, feeAmount)

  const fee = await readContract(config, {
    address: poolAddress,
    abi: IUniswapV3PoolABI.abi,
    functionName: 'fee',
  })

  return fee
}
