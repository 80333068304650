// frontend/web3/services/jupiterApi.js

const API_ENDPOINT = "https://quote-api.jup.ag/v6";
const PRICE_API_ENDPOINT = "https://api.jup.ag/price/v2";

// Function to get swap instructions
export const getSwapIx = async (user, quoteResponse, maxSlippageBps = 300) => {
  try {
    const data = {
        userPublicKey: user.toString(),
        wrapAndUnwrapSol: true,
        prioritizationFeeLamports: { "autoMultiplier": 3 },
        asLegacyTransaction: false,
        dynamicComputeUnitLimit: true,
        allowOptimizedWrappedSolTokenAccount: false,
        quoteResponse,
        dynamicSlippage: { maxBps: maxSlippageBps }
      };
      const response = await fetch(`${API_ENDPOINT}/swap-instructions`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Error fetching swap instructions: ${response.statusText}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error in getSwapIx:", error);
    throw error;
  }
};

// Function to fetch a quote from Jupiter API
export async function getQuote(inputMint, outputMint, amount, swapMode = "ExactOut", slippageBps = 300,) {
  try {
    // Construct the URL with all parameters
    const url = `${API_ENDPOINT}/quote?inputMint=${inputMint}&outputMint=${outputMint}&amount=${amount}&swapMode=${swapMode}&slippageBps=${slippageBps}&onlyDirectRoutes=false&asLegacyTransaction=false&minimizeSlippage=false
`;
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Error fetching quote: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error in getQuote:", error);
    throw error;
  }
}


// Function to get the price of tokens using Jupiter's Price API V2
export async function getPrice(tokenIds, showExtraInfo = false) {
  try {
    // Convert token IDs into a comma-separated string for the query parameter
    const idsParam = tokenIds.join(",");
    const url = `${PRICE_API_ENDPOINT}?ids=${idsParam}${showExtraInfo ? "&showExtraInfo=true" : ""}`;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Error fetching price data: ${response.statusText}`);
    }

    const result = await response.json();
    return result.data;
  } catch (error) {
    console.error("Error in getPrice:", error);
    throw error;
  }
}
