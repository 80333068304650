import { registerControllers } from 'stimulus-vite-helpers'
import { application } from './application'
import Notification from '@stimulus-components/notification'
import RevealController from '@stimulus-components/reveal'
import Dialog from '@stimulus-components/dialog'
import Chart from '@stimulus-components/chartjs'

const controllers = import.meta.glob('./**/*_controller.js', { eager: true })
registerControllers(application, controllers)

application.register('notification', Notification)
application.register('reveal', RevealController)
application.register('dialog', Dialog)
application.register('chart', Chart)
