import { getAddress } from 'viem';
import { getTokenPrice } from '@/web3/logic/getTokenPrice.js';
import { Token } from '@uniswap/sdk-core';
import { getPrice } from '@/web3/services/jupiterApi.js';


export async function getTokenPriceRep(tokenIn, chain) {
  if (tokenIn.symbol === chain.recipient_currency.symbol) return 1;

  if (chain.name_space === "solana") {
    try {
      // Call getPrice from Jupiter API for the given token
      const tokenIds = [tokenIn.address]; // Use the Solana token's address as the ID
      const prices = await getPrice(tokenIds);

      // Extract the price from the response
      const priceData = prices[tokenIn.address];
      if (priceData && priceData.price) {
        return parseFloat(priceData.price);
      } else {
        console.error(`Price not found for token: ${tokenIn.symbol}`);
        return 1;
      }
    } catch (error) {
      console.error(`Error fetching price for token ${tokenIn.symbol}:`, error);
      return 1;
    }
  } else {
    const tokenOut = createTokenInstance(chain.recipient_currency, Number(chain.chain_id));
    tokenIn = createTokenInstance(tokenIn,  Number(chain.chain_id));
    const { averages } = await getTokenPrice(tokenIn, tokenOut);

    if (!averages) return 1;

    const repPrice = extractSignificantPrice(averages);

    return repPrice;
  }
}

export function createTokenInstance(token, chainId) {
  return new Token(
    chainId,
    getAddress(token.address),
    token.decimals,
    token.symbol,
    token.name
  );
}

export function extractSignificantPrice(averages) {
  if (!averages) {
    console.log(`Error: averages ${averages}`);
    return;
  }

  const result = averages.invert
    ? averages.twap.invert().toSignificant()
    : averages.twap.toSignificant();

  return result;
}
